import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";
import OrderedList from "~/components/List/Ordered";
import Button from "~/components/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={2}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 2</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p> The 3 websites linked below all focus on CBD.</p>
              <p>Remember, the question you want to answer is, "Is CBD use to relieve chronic pain supported by science and medicine?"</p>
              <p>Work with your teammates to scan each site. At this point, do not try to read everything. You should be able to do this in about 5 minutes. After you have looked at the sites, discuss the following questions:</p>

              <OrderedList>
                <li>Do you think all 3 of the websites are equal in quality, or are any likely to be better than the others for answering your question?</li>
                <li>What features make you think that one site is better than others?</li>
                <li>Are there specific things on any of the websites that make you question the information on the site? What are those features?</li>
                <li>Which website would you recommend for other people to look at? Why?</li>
              </OrderedList>

              <p>Write your answers in your science notebook.</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/article/cbd/cbd-now"
                  icon="external"
                  title="CBD website 1"
                >
                  CBD website 1
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/article/cbd/cbd-truth"
                  icon="external"
                  title="CBD website 2"
                >
                  CBD website 2
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="indigo"
                  href="/article/cbd/cansativa-organics"
                  icon="external"
                  title="CBD website 3"
                >
                  CBD website 3
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Have students work in teams of 2–3 to look at 3 websites that focus on CBD. Students should be able to scan these websites in about 5 minutes (or less) and should not spend time reading all the text on these sites. The goal of this step is for students to think about how they currently judge a website that they see and give their first impressions about the quality of a site. After they have a chance to complete this step, hold a brief class discussion to draw out student ideas.</p>

              <UnorderedList>
                <li>What features (color, appealing graphics, lots of text, and so forth) do students think make one website better than others?</li>
              </UnorderedList>

              <p>At this point, elicit students’ current ideas but do not correct any preconceptions they have. Students will return to these CBD websites at several points during the module as they learn more about strategies for evaluating information from websites and other media. One thing that you may need to reinforce is the question posed related to CBD use. The question asks about whether science and medicine would support the use of CBD for chronic pain relief.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;


